import React from "react";
import Study from "../components/Study/Study";

const StudyPage = () => {
  return (
    <div>
      <Study />
    </div>
  );
};

export default StudyPage;
