import React from "react";
import DeckInfo from "../components/DeckInfo/DeckInfo";

const DeckInfoPage = () => {
  return (
    <div>
      <DeckInfo />
    </div>
  );
};

export default DeckInfoPage;
